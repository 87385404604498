<template>
  <el-dialog
    width="80%"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    append-to-body
  >
    <div>
      <div class="fs-20 fw-700 text-center">Phiếu khám</div>
      <p class="fs-20 fw-700 txt-pri mb-0">{{user && user.name}}</p>
      <p class="fs-16 txt-neutral-450 fw-400  mb-0"> <span class="mr-3 fw-500">{{user && user.gender === 1 ? 'Nam' : 'Nữ'}}</span> {{user ? user.birthday : ''}} {{user ? user.career : ''}}</p>

      <p class="fs-16 fw-400 mb-0 txt-neutral-700">SĐT: {{user && user.phone}}</p>
      <p class="fs-16 fw-400 mb-0 txt-neutral-700">Địa chỉ: {{user && user.address}}</p>
      <p class="fs-16 fw-400 mb-3 txt-neutral-700">
        Người liên hệ: {{(user && user.emergency_contact) || ''}}
        {{user && user.contact_phone ? ', SĐT: ' + user && user.contact_phone : ''}}
      </p>
    </div>
    <div>
      <div class="mb-3">
        <span class="fs-16 fw-700">Thời gian</span>
        <span class="fs-16 fw-400 ml-3">{{ getDateTime(consultation.start_time) }}</span>
      </div>
      <div class="row mb-3">
        <div class="col-3 fs-16 fw-700">Lý do khám bệnh</div>
        <div class="col-9 fs-16 fw-400">{{ consultation.reason }}</div>
      </div>
      <div class="row mb-3">
        <div class="col-3 fs-16 fw-700">Diễn biến</div>
        <div class="col-9 fs-16 fw-400">{{ consultation.progression }}</div>
      </div>
      <div class="row mb-3">
        <div class="col-3 fs-16 fw-700">Chỉ định</div>
        <div class="col-9 fs-16 fw-400">{{ consultation.indication }}</div>
      </div>
      <div class="row mb-3">
        <div class="col-3 fs-16 fw-700">Kết quả xét nghiệm</div>
        <div class="col-9 fs-16 fw-400">{{ consultation.prehistoric }}</div>
      </div>
      <div class="row mb-3">
        <div class="col-3 fs-16 fw-700">Chẩn đoán</div>
        <div class="col-9 fs-16 fw-400">{{ consultation.diagnose }}</div>
      </div>
    </div>
    <div class="flex justify-end">
      <el-button @click="close">Đóng</el-button>
      <el-button
        @click="update"
        type="primary"
        v-if="consultation && this.$user.doctor&& consultation.doctor_id === this.$user.doctor.id"
      >Sửa đổi</el-button>
    </div>

    <PhieuKhamBenhUpdate
      :hr="consultation"
      :user="user"
      ref="PhieuKhamBenhUpdate"
    />
  </el-dialog>
</template>
    
    
  <script>
// import moment from 'moment'
import PhieuKhamBenhUpdate from './ModalUpdate'
export default {
  name: 'PhieuKhamBenhDetail',
  props: ['user'],
  components: { PhieuKhamBenhUpdate },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      consultation: {}
    }
  },
  mounted () {
  },
  methods: {
    update () {
      this.dialogVisible = false
      this.$refs.PhieuKhamBenhUpdate.openDialog()
    },
    close () {
      this.dialogVisible = false
    },
    getDateTime (date_time) {
      return date_time && window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    async openDialog (item) {
      this.dialogVisible = true
      this.loading = true
      try {
        const res = await this.$rf.getRequest('DoctorRequest').getConsultationDetail(item.id)
        this.consultation = res.data
      } finally {
        this.loading = false
      }
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
  </script>
    
<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>