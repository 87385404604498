<template>
    <div class="p-lg-8 mt-4">
        <!-- Page title -->
        <div class="row p-1">
            <div class="col-12">
                <div class="tab-content">
                    <div class="tab-pane active" id="tab__pending">
                        <div class="py-3">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="mb-1 d-flex patient-search-box pl-3">
                                        <i class="fa fa-search font-size-20 cursor-pointer" @click="getPersons"></i>
                                        <input type="text" class="d-inline border-0 patient-search-input ml-3 w-100" v-on:keyup.enter="getPersons(10, search)" v-model="search" :placeholder="$t('title.search')">
                                    </div>
                                </div>
                                <div class="col-lg-4 offset-lg-4 text-right">
                                    <a href="javascript:;" class="btn btn-add mr-1 mb-1" @click="onShowModalAdd">
                                        <span class="fa fa-user-plus mr-1"></span>
                                        {{ $t("title.person.add") }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <DoctorPersonTable @sortPersons="sortPersons" @getMorePersons="getPersons" :isClinic="false" :item_persons="doctor_persons" />
                    </div>
                    <div class="tab-pane" id="tab__active"></div>
                    <div class="tab-pane" id="tab__past"></div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal__add">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{$t('title.person.add')}}</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!--<ul class="nav nav-tabs nav-tabs-alt" data-toggle="tabs">-->
                        <!--<li class="nav-item">-->
                        <!--<a href="#tab__qr" class="nav-link active" data-toggle="tab">Đã có hồ sơ</a>-->
                        <!--</li>-->
                        <!--<li class="nav-item">-->
                        <!--<a href="#tab__no_account" class="nav-link active" data-toggle="tab">Chưa có hồ sơ</a>-->
                        <!--</li>-->
                        <!--</ul>-->
                        <!--<div class="tab-content">-->
                        <!--<div class="tab-pane active" id="tab__qr">-->
                        <!--<div class="card-body py-3">-->
                        <!--<div class="mb-3">-->
                        <!--<label class="form-label">Mã QR của Workspace:</label>-->
                        <!--<div class="text-center">-->
                        <!--<qrcode v-if="user" :value="'CLI' + user.clinic.id" :options="{ width: 300 }"-->
                        <!--class="form-control"></qrcode>-->
                        <!--</div>-->
                        <!--<small class="text-muted">Dùng điện thoại của bệnh nhân đã đăng nhập tài khoản để quét mã QR này. Sau đó hệ thống sẽ tự động thêm hồ sơ bệnh nhân vào hệ thống quản lý của Workspace của bạn.</small>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--<div class="tab-pane" id="tab__no_account">-->
                        <PersonForm :is-edit="false" :birth="add_birth" :form="add_person" :loading="loading" @onSubmit="onAddNewPerson" :is_appointment_add="false" :is_person_listing="true"></PersonForm>
                        <!--</div>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mixinGetPerson } from '../../utils/mixinGetPerson'
import DoctorPersonTable from '../../components/DoctorPersonTable'
import Pagination from '../../components/Pagination'
import { mixinPersonAdd } from '../../utils/mixinPersonAdd'
export default {
  mixins: [mixinGetPerson, mixinPersonAdd],
  name: 'DoctorPersonListing',
  components: { Pagination, DoctorPersonTable },
  data () {
    return {
      loading: false,
      upload_noti: '',
      isClinic: false,
      is_person_listing: true,
      is_appointment_add: false
    }
  },
  async mounted () {
    let self = this
    self.getPersons(self.$route.query.limit ? self.$route.query.limit : 10)
  },
  methods: {
    onShowModalAdd () {
      window.$('#modal__add').modal('show')
    }
  }
}
</script>