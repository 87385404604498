<template>
  <div v-loading="isLoading" class="text-black">
    <div class="flex flex-col xl:flex-row gap-2 hodo-el mb-2">
      <div class="filter-item">
        <small>{{ $t("Từ ngày") }}</small>
        <div>
          <el-date-picker
            @input="handleDebounce"
            v-model="from_date"
            type="date"
            placeholder="Từ ngày"
          ></el-date-picker>
        </div>
      </div>
      <div class="filter-item">
        <small>{{ $t("Đến ngày") }}</small>
        <div>
          <el-date-picker
            @input="handleDebounce"
            v-model="to_date"
            type="date"
            placeholder="Đến ngày"
          ></el-date-picker>
        </div>
      </div>
      <div class="flex items-end justify-end h-full self-end">
        <el-button @click="exportExcel" type="primary">
          {{ $t("Xuất excel") }}
        </el-button>
      </div>
    </div>

    <el-table class="cs-table" :data="tableData" style="width: 100%">
      <el-table-column label="#" type="index" width="50"></el-table-column>
      <el-table-column
        prop="date"
        :formatter="getDateTime"
        label="Ngày áp dụng"
      ></el-table-column>
      <el-table-column
        prop="patient_name"
        label="Tên tài khoản"
      ></el-table-column>
      <el-table-column prop="hid" label="HID"></el-table-column>
      <el-table-column prop="pid" label="Mã Bệnh nhân(PID)"></el-table-column>
      <el-table-column prop="ref_code" label="Mã áp dụng"></el-table-column>
      <el-table-column
        prop="created_by_infor.name"
        label="Điều dưỡng"
      ></el-table-column>
      <el-table-column
        :formatter="handleGetPatientRelations"
        label="Người thân"
      ></el-table-column>
      <el-table-column
        prop="org_info.name"
        label="Tên Tổ chức"
      ></el-table-column>
    </el-table>
    <AppPagination
      :paginationProps="pagination"
      @onChangePagination="getPatientVisitReport"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import AppPagination from '../../components/Common/AppPagination.vue'
import ExcelJS from 'exceljs'

export default {
  components: { AppPagination },
  name: 'PatientVisitReport',
  data () {
    return {
      tableData: [],
      isLoading: false,
      to_date: null,
      from_date: null,
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      }
    }
  },
  created () {
    this.getPatientVisitReport()
  },
  methods: {
    getDateTime (row) {
      if (!row.created_at) return ''

      return window.moment(row.created_at).format('HH:mm:ss DD/MM/YYYY')
    },
    async getPatientVisitReport (data) {
      try {
        const params = {
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          sort_by: 'created_at',
          order: 'desc',
          org_id: this.$globalClinic?.org_id
        }

        if (this.from_date && this.to_date) {
          params.from_date = window
            .moment(this.from_date)
            .startOf('day')
            .unix()

          params.to_date = window.moment(this.to_date).endOf('day').unix()
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getRefPartnerPatients(params)

        this.tableData = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num || 1,
          pageSize: response.data?.page?.page_size || 15,
          totalItems: response.data?.page?.total
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleDebounce: debounce(function () {
      this.getPatientVisitReport({
        currentPage: 1
      })
    }, 500),
    async exportExcel () {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1', {
        views: [{ showGridLines: false }],
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true
        }
      })

      // Access columns from B to J and set their widths
      const defaultColWidth = 30
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1) // Columns are 1-indexed
        if ([1, 4, 7].includes(index)) {
          column.width = 28
        } else {
          column.width = defaultColWidth
        }
      }

      worksheet.properties.defaultRowHeight = 14
      const START_ROW = 1

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`D${START_ROW}`)
      nameCell.value = 'Báo cáo đối soát'
      nameCell.font = { size: 20, bold: true }
      worksheet.mergeCells(`D${START_ROW}:G${START_ROW}`)

      //   const inventoryNameCell = worksheet.getCell(`B${START_ROW + 1}`);
      //   inventoryNameCell.value = this.inventoryName?.toUpperCase();
      //   inventoryNameCell.font = { size: 12, bold: true };
      //   worksheet.mergeCells(`B${START_ROW + 1}:E${START_ROW + 1}`);

      //   const dateCell = worksheet.getCell(`B${START_ROW + 2}`);
      //   dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      //   dateCell.font = { bold: false, italic: true };
      //   worksheet.mergeCells(`B${START_ROW + 2}:E${START_ROW + 2}`);
      const tableHeader = [
        'STT',
        'Ngày áp dụng',
        'Tên bệnh nhân',
        'HID',
        'Mã bệnh nhân(PID)',
        'Mã áp dụng',
        'Điều dưỡng',
        'Người thân',
        'Tên Tổ chức'
      ]
      // Generate the main table (your data) with borders and style
      const tableData = this.tableData.map((item, i) => {
        return [
          i + 1,
          this.getDateTime(item) || '',
          item.patient_name || '',
          item.hid || '',
          item.pid,
          item.ref_code || '',
          item.created_by_infor?.name || '',
          this.handleGetPatientRelations(item) || '',
          item.org_info?.name || ''
        ]
      })

      const mainTableData = [
        [], // empty row
        tableHeader,
        ...tableData
      ]
      worksheet.addRows(mainTableData)
      const mainTable = worksheet.getRows(3, mainTableData.length - 1)
      mainTable.forEach((row, rowIndex) => {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
          cell.font = { size: 12 }
          if (!rowIndex) {
            cell.font = { bold: true }
          }
        })
      })
      // default styles
      workbook.eachSheet((sheet) => {
        sheet.eachRow((row) => {
          row.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true
          }
          row.eachCell((cell) => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: 'Times New Roman'
                // size: 12
              })
            }
          })
        })
      })

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `BaoCaoDoiSoat_${window.moment(Date.now()).format('DD-MM-YYYY')}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleGetPatientRelations (row) {
      return row.person_relations?.map((item) => item?.name)?.join(', ') || ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-table {
    thead {
      color: black !important;
    }
  }
}

.filter-item {
  min-width: 220px;
  width: 100%;

  .el-select {
    width: 100%;
  }
}

.cs-search {
  min-width: 350px !important;
}
</style>