<template>
  <div class="flex flex-col xl:flex-row items-center gap-2 hodo-el">
    <div class="flex-1 filter-item cs-search">
      <small>{{ $t("Tìm kiếm") }}</small>
      <el-input
        placeholder="Nhập tên BN, SĐT, Mã lượt khám, Tên chỉ định..."
        v-model="searchValue"
        @input="handleDebounce"
        clearable
      ></el-input>
    </div>
    <div class="filter-item" v-if="isBVHV">
      <small>{{ $t("Bác sĩ khám") }}</small>
      <div>
        <el-select
          v-model="doctorID"
          @input="handleDebounceSearch"
          clearable
          placeholder="Chọn bác sĩ khám"
          remote
          filterable
          reserve-keyword
          :remote-method="handleDebounceSearch"
        >
          <el-option
            v-for="item in doctors"
            :key="item.id"
            :label="item.name"
            :value="item.hodo_id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="filter-item">
      <small>{{ $t("Trạng thái trả kết quả") }}</small>
      <div>
        <el-select
          v-model="status"
          @input="handleDebounce"
          clearable
          placeholder="Chọn trạng thái trả kết quả"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="filter-item">
      <small>{{ $t("Từ ngày") }}</small>
      <div>
        <el-date-picker
          @input="handleDebounce"
          v-model="from_date"
          type="date"
          placeholder="Từ ngày"
        ></el-date-picker>
      </div>
    </div>
    <div class="filter-item">
      <small>{{ $t("Đến ngày") }}</small>
      <div>
        <el-date-picker
          @input="handleDebounce"
          v-model="to_date"
          type="date"
          placeholder="Đến ngày"
        ></el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'FilterPVResult',
  data () {
    return {
      searchValue: '',
      statusOptions: [
        {
          value: 1,
          label: 'Chưa trả kết quả'
        },
        {
          value: 2,
          label: 'Đã trả kết quả'
        },
        {
          value: 3,
          label: 'Gửi tin nhắn thất bại'
        }
      ],
      status: null,
      to_date: null,
      from_date: null,
      doctors: [],
      doctorID: null
    }
  },
  computed: {
    isBVHV () {
      return this.$globalClinic?.org_id === 4 // Bệnh viện HÙng Vương
    }
  },
  created () {
    if (this.isBVHV) {
      this.getDoctorHis()
    }
  },
  methods: {
    handleDebounce: debounce(function () {
      const status = this.status === 3 ? undefined : this.status
      const error_send = this.status === 3 ? true : undefined

      this.$emit('onChangeFilter', {
        keyword: this.searchValue,
        is_send_result: status,
        to_date:
          this.to_date && this.from_date
            ? window
              .moment(this.to_date)
              .endOf('day')
              .unix()
            : undefined,
        from_date: this.from_date
          ? window
            .moment(this.from_date)
            .startOf('day')
            .unix()
          : undefined,
        // his_id: this.doctorID || undefined,
        doctor_id: this.doctorID || undefined,
        error_send
      })
    }, 500),
    async getDoctorHis (value) {
      try {
        const params = {
          page: 1,
          page_size: 100,
          keyword: value,
          org_id: 4, // get doctor his Bệnh viện Hùng Vương,
          type: 2
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorHIS(params)

        this.doctors = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    handleDebounceSearch: debounce(function (value) {
      this.getDoctorHis(value)
      this.handleDebounce()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.filter-item {
  min-width: 180px;
  width: 100%;

  .el-select {
    width: 100%;
  }
}

.cs-search {
  min-width: 300px !important;
}
</style>