<template>
  <div>
    <el-dialog :title="'Trả kết quả'" :visible.sync="isOpen" width="50%" center>
      <div class="flex flex-col gap-3">
        <div class="fs-16">
          {{ $t("Trả thành công") }}
          <span style="color: #20409b" class="fs-18 font-bold">{{
            `${totalSuccess}/${totalItem}`
          }}</span>
          {{ $t("kết quả") }}
        </div>
        <div v-if="totalError" class="fs-16">
          <div class="mb-2">
            {{ $t("Danh sách Bệnh nhân chưa nhận được tin nhắn:") }}
          </div>
          <ul>
            <li
              class="font-bold"
              v-for="(item, index) in listError"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>

          <span class="italic">{{
            $t(
              "Vui lòng đợi sau 5 phút để tiếp tục gửi lại kết quả cho những bệnh nhân này"
            )
          }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalSentMessageError',
  data () {
    return {
      isOpen: false,
      listError: [],
      totalItem: 0,
      totalError: 0
    }
  },
  computed: {
    totalSuccess () {
      return this.totalItem - this.totalError
    }
  },
  methods: {
    handleOpenModal (listData, totalCount, errorCount) {
      this.listError = listData || []
      this.totalItem = totalCount || 0
      this.totalError = errorCount || 0
      this.isOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>