<template>
  <div v-loading="isLoading" class="p-2 h-full">
    <FilterPVResult @onChangeFilter="handleChangeFilters" />
    <div class="flex flex-col xl:flex-row gap-3 overflow-x-scroll mt-3">
      <div class="bg-white p-2 flex-1" style="width: 60%">
        <el-table
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          class="cs-table"
          :data="tableData"
          style="width: 100%"
          row-key="id"
        >
          <el-table-column
            :selectable="handleCheckShowSelect"
            reserve-selection
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column label="Tên bệnh nhân">
            <template slot-scope="scope">{{ scope.row.person && scope.row.person.name }}</template>
          </el-table-column>
          <el-table-column label="Số ĐT" width="110">
            <template slot-scope="scope">{{ scope.row.person && scope.row.person.phone }}</template>
          </el-table-column>

          <el-table-column label="Ngày sinh" width="100">
            <template slot-scope="scope">{{ getDateTimeBorn(scope.row.person) }}</template>
          </el-table-column>
          <el-table-column label="Bác sĩ khám">
            <template
              slot-scope="scope"
            >{{ scope.row.doctor && scope.row.doctor.User && scope.row.doctor.User.name || scope.row.doctor_ref_name }}</template>
          </el-table-column>
          <el-table-column label="Thời gian khám">
            <template slot-scope="scope">{{ getDateTime(scope.row) }}</template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="Tên chỉ định"></el-table-column> -->
          <el-table-column prop="status" label="Trạng thái">
            <template slot-scope="scope">
              <div v-if="checkErrorSendMessage(scope.row)" class="text-warning">
                <div class="text-warning">{{ $t("Gửi tin nhắn thất bại") }}</div>
                <small>{{ `Thử lại sau ${getErrorTime(scope.row)}` }}</small>
              </div>
              <span
                v-else
                :class="scope.row.sent_result_count > 0
                  ? 'text-success'
                  : 'text-danger'
                "
              >
                {{
                scope.row.sent_result_count > 0
                ? "Đã trả kết quả"
                : "Chưa trả kết quả"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="50">
            <template slot-scope="scope">
              <svg
                v-if="scope.row.document_link"
                @click="handleViewDetail(scope.row)"
                class="cursor-pointer"
                width="30"
                height="26"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                <mask
                  id="mask0_35008_774"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="6"
                  y="6"
                  width="18"
                  height="18"
                >
                  <rect
                    x="6.92859"
                    y="6.92871"
                    width="16.1429"
                    height="16.1429"
                    fill="#D9D9D9"
                    stroke="#20409B"
                  />
                </mask>
                <g mask="url(#mask0_35008_774)">
                  <path
                    d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                    fill="#20409B"
                    stroke="#20409B"
                    stroke-width="0.025"
                  />
                </g>
              </svg>
            </template>
          </el-table-column>
        </el-table>
        <AppPagination :paginationProps="pagination" @onChangePagination="getListPatientVisit" />
      </div>
      <div class="bg-white p-2" style="width: 30%">
        <div class="flex justify-between gap-2 items-center cs-border">
          <div class="fs-18 font-bold">
            <span class>{{ $t("Đã chọn:") }}</span>
            <span class="text-blue-900 ml-2">
              {{
              patientVisitSelected.length
              }}
            </span>
          </div>
          <div>
            <el-button
              round
              plain
              class="flex items-center"
              size="medium"
              type="primary"
              @click="handleSubmitIndication"
              :disabled="!patientVisitSelected.length"
            >
              <span class="fs-16">{{ "Trả kết quả" }}</span>
              <i class="el-icon-s-promotion fs-18 ml-1"></i>
            </el-button>
          </div>
        </div>

        <el-table
          @selection-change="handleSelectionChange"
          class="cs-table"
          :data="patientVisitSelected"
          style="width: 100%"
        >
          <el-table-column label="Tên bệnh nhân">
            <template slot-scope="scope">{{ scope.row.person && scope.row.person.name }}</template>
          </el-table-column>
          <el-table-column label="Thời gian khám" width="130">
            <template slot-scope="scope">{{ getDateTime(scope.row) }}</template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="Tên chỉ định"></el-table-column> -->
          <el-table-column fixed="right" label width="50">
            <template slot-scope="scope">
              <i
                @click="handleRemove(scope.row)"
                class="el-icon-delete text-danger cursor-pointer fs-18"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <ModalSentMessageError ref="ModalSentMessageError" />
  </div>
</template>

<script>
import { AppPagination } from '@/components/Common'
import appUtils from '@/utils/appUtils'
import mdtUtils from '@/utils/mdtUtils'
import FilterPVResult from './FilterPVResult.vue'
import ModalSentMessageError from './ModalSentMessageError.vue'

export default {
  name: 'PatientVisitResult',
  components: { FilterPVResult, AppPagination, ModalSentMessageError },
  data () {
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      patientVisitSelected: [],
      isLoading: false,
      filterData: {}
    }
  },
  created () {
    this.getListPatientVisit()
  },
  mounted () {
    if (this.$refs.multipleTable) {
      this.$refs.multipleTable.clearSelection()
    }
  },
  methods: {
    async getListPatientVisit (data) {
      if (!this.$globalClinic?.id) return

      try {
        this.isLoading = true
        const params = {
          order: 'desc',
          sort_by: 'start_time',
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          clinic_id: this.$globalClinic?.id,
          ...(this.filterData || {})
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getPatientVisitsV2(params)

        this.tableData = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num,
          totalItems: response.data?.page?.total,
          pageSize: response.data?.page?.page_size
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectionChange (data) {
      this.patientVisitSelected = data
    },
    handleRemove (item) {
      this.patientVisitSelected.filter(
        patient_visit => patient_visit.id !== item.id
      )

      this.$refs.multipleTable.toggleRowSelection(item, false)
    },
    async handleSubmitIndication () {
      try {
        const r = await this.$confirm(
          'Bạn có chắc chắn muốn gửi kết quả về cho bệnh nhân không?',
          'Xác nhận gửi kết quả',
          {
            dangerouslyUseHTMLString: true,
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm')
          }
        ).catch(() => {})

        if (!r) return
        this.isLoading = true
        const params = {
          patient_visit_ids: this.patientVisitSelected?.map(item => item?.id)
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .sendResultByPatientVisit(params)

        if (response.status === 200) {
          this.patientVisitSelected = []
          this.$refs.multipleTable.clearSelection()

          const responseData = response.data
          if (responseData?.failed) {
            this.$refs.ModalSentMessageError.handleOpenModal(
              responseData?.error_patients,
              responseData.total,
              responseData.failed
            )
            this.getListPatientVisit()

            return
          }

          this.pagination = {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0
          }
          this.getListPatientVisit()

          this.$toast.open({
            message: this.$t('Trả kết quả thành công'),
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getDateTime (row) {
      return row.start_time
        ? window
          .moment(row.start_time)
          .utc()
          .format('HH:mm DD/MM/YYYY')
        : ''
    },
    handleChangeFilters (data) {
      let start_date = new Date(data.from_date * 1000)
      let end_date = new Date(data.to_date * 1000)
      this.filterData = {
        error_send: data.error_send,
        his_id: data.his_id,
        doctor_id: data.doctor_id,
        is_send_result: data.is_send_result,
        keyword: data.keyword,
        start_time: data.from_date
          ? window.moment(start_date).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        end_time: data.to_date
          ? window.moment(end_date).format('YYYY-MM-DD HH:mm:ss')
          : undefined
      }
      this.getListPatientVisit({
        currentPage: 1,
        pageSize: 10
      })
    },
    handleViewDetail (row) {
      window.open(row.document_link, '_blank')
    },
    getDateTimeBorn (row) {
      if (!row.birthday) return ''

      return `${appUtils.formatDate(row.birthday)} (${mdtUtils.getAges(
        new Date(row.birthday)
      )})`
    },
    handleCheckShowSelect (row) {
      if (!row.sent_result_count && row.last_sent_result) {
        const lastSendResult = window
          .moment(row.last_sent_result)
          .add(5, 'minutes')
          .unix()

        const now = window.moment().unix()
        return lastSendResult <= now
        // return false
      }

      return true
    },
    checkErrorSendMessage (row) {
      if (!row.sent_result_count && row.last_sent_result) {
        return true
        // const lastSendResult = window
        //   .moment(row.last_sent_result)
        //   .add(5, 'minutes')
        //   .unix()

        // const now = window.moment().unix()
        // return lastSendResult > now
      }

      return false
    },
    getErrorTime (row) {
      return window
        .moment(row.last_sent_result)
        .add(5, 'minutes')
        .format('HH:mm DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-table * {
    color: black !important;
  }

  .text-success {
    color: #5eba00 !important;
  }

  .text-info {
    color: #4299e1 !important;
  }

  .text-warning {
    color: #ed8936 !important;
  }

  .text-danger {
    color: #e53e3e !important;
  }

  .cs-border {
    border-bottom: 1px solid #dedede !important;
    padding-bottom: 16px;
  }
}
</style>
