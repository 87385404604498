<template>

  <el-dialog
    title="Sửa phiếu khám bệnh"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
    append-to-body
  >
    <div class="body-container txt-black">
      <p class="fs-18 fw-700 txt-pri mb-0">{{!user ? form && form.patient_name : user && user.name}}</p>
      <p class="fs-14 txt-neutral-450 fw-400"> <span class="mr-3 fw-500">{{!user ? (form && form.gender === 1 ? 'Nam' : 'Nữ')  || '' : (user && user.gender === 1 ? 'Nam' : 'Nữ')  || ''}}</span> {{!user ? (form && form.birthday ? form && form.birthday : '') : (user && user.birthday ? user && user.birthday : '')}} {{!user ? (form && form.career) || '' : (user && user.career) || ''}}</p>

      <p class="fs-16 fw-400 mb-0 txt-neutral-700">SĐT: {{!user ? form && form.phone : user && user.phone}}</p>
      <p class="fs-16 fw-400 mb-0 txt-neutral-700">Địa chỉ: {{!user ? form && form.address : user && user.address}}</p>
      <p class="fs-16 fw-400 mb-4 txt-neutral-700">
        Người liên hệ: {{(form && form.emergency_contact) || ''}}
        {{form && form.contact_phone ? ', SĐT: ' + form.contact_phone : ''}}
      </p>
      <p class="fs-16 fw-500 mb-2">Thời gian</p>
      <div class="col-lg-8 col-md-7 col-xl-9 mb-12">
        <div class="row">
          <div class="col-md-6 d-flex">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              placeholder="Chọn ngày và giờ"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Lý do khám bệnh
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Tư vấn quá trình điều trị"
          v-model="form.reason"
        >

                      </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">
          Diễn biến
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.progression"
        >

                      </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Chỉ định
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.indication"
        >

                      </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Kết quả xét nghiệm
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.prehistoric"
        >

                      </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Chẩn đoán
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.diagnose"
        >

                      </textarea>
      </div>
      <div>
        <TempPhieuKhamBenh
          v-if="form && !loading"
          :update_id="(hr && hr.id) || null"
          :is_update="is_update = true"
          :form="form"
          :user="user"
          :close="handleClose"
          @saveFile="saveFileComplete"
        ></TempPhieuKhamBenh>
      </div>
      <div
        class="p-center d-flex justify-content-center align-items-center"
        v-if="loading"
      >
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
        ></span>
      </div>
    </div>
  </el-dialog>

</template>
    <script>
import TempPhieuKhamBenh from '../../templates/PhieuKhamBenh/PhieuKhamBenh.vue'
export default {
  name: 'PhieuKhamBenhUpdate',
  components: { TempPhieuKhamBenh },
  props: ['hr', 'user'],
  data () {
    return {
      clinic: null,
      loading: false,
      dialogVisible: false,
      form: {
        progression: '',
        diagnose: '',
        indication: '',
        reason: '',
        prehistoric: '',
        patient_name: '',
        birthday: '',
        career: '',
        gender: '',
        address: '',
        phone: '',
        emergency_contact: '',
        contact_phone: '',
        start_time: window.moment(),
        city: '',
        appt_id: null,
        notes: '',
        consultation: null
      }
    }
  },
  watch: {
    hr: function (hr, old) {
      if (hr) {
        this.form.progression = hr.progression
        this.form.diagnose = hr.diagnose
        this.form.indication = hr.indication
        this.form.prehistoric = hr.prehistoric
        this.form.reason = hr.reason
        this.form.emergency_contact = this.user?.emergency_contact
        this.form.contact_phone = this.user?.contact_phone
        this.form.start_time = hr.start_time
      }
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    openDialog () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    showModalPrint (show) {
      window.$('#modal__print').modal(show ? 'show' : 'hide')
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return bd.format('DD/MM/YYYY') + ' (' + age + ' tuổi)'
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getTime (date) {
      return window.moment(date).format('HH:mm')
    },
    getDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    saveFileComplete (data) {
      this.$emit('saveFileComplete', data)
    }
  }
}
    </script>
    <style lang="css" scoped>
</style>
    