<template>
  <div v-if="patientVisit">
    <div class="hidden py-20 px-4 hodo-table">
      <div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="font-weight: 700">KCC</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo KCC:</td>
              <td>.....tuần .....ngày</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ngày DKS</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo DKS:</td>
              <td>.....tuần .....ngày</td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                colspan="4"
                style="
                  font-size: 20px;
                  font-weight: 700;
                  text-align: center;
                  text-transform: uppercase;
                "
              >
                SIÊU ÂM THAI HÌNH THÁI THAI DƯỚI 12 TUẦN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="font-weight: 700">
                <span>Tư thế tử cung</span>
              </td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">
                <span>GS (mm)</span>
              </td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>KT (mm)</span>
              </td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">
                <span>CRL (mm)</span>
              </td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700" rowspan="2">
                <span>Buồng tử cung</span>
              </td>
              <td style="text-align: center" rowspan="2"></td>
              <td style="font-weight: 700">
                <span>BPD (mm)</span>
              </td>
              <td style="text-align: center">&nbsp;</td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>FL (mm)</span>
              </td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>Số lượng thai</span>
              </td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">
                <span>KSSG (mm)</span>
              </td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>Tim thai</span>
              </td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">
                <span>Xương sống mũi</span>
              </td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>Nước ối</span>
              </td>
              <td style="text-align: center">
                <span>Bình thường</span>
              </td>
              <td style="font-weight: 700">
                <span>Bánh rau</span>
              </td>
              <td style="text-align: center">
                <span>Bám rộng</span>
              </td>
            </tr>
            <tr>
              <td style="font-weight: 700">
                <span>H/a khác</span>
              </td>
              <td colspan="3" style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700" colspan="4">
                <span>Ngày dự kiến sinh:</span>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="text-transform: uppercase">
                <strong>HẸN TUẦN SIÊU ÂM LẠI</strong>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="font-style: italic">
                Khi khám lại nhớ mang theo kết quả này.
              </td>
            </tr>
            <tr>
              <td colspan="4" style="font-style: italic">
                Các thời điểm siêu âm quan trọng: thai 12 tuần, 22 tuần, 32
                tuần.
              </td>
            </tr>
            <tr>
              <td colspan="4" style="font-style: italic">
                Đề nghị làm Double-Test vào thời điểm thai 11-13 tuần.
              </td>
            </tr>
            <tr>
              <td colspan="4" style="font-style: italic">
                Làm Triple-Test vào thời điểm thai 16-18 tuần.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="font-weight: 700">KCC</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo KCC:</td>
              <td>.....tuần .....ngày</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ngày DKS</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo DKS:</td>
              <td>.....tuần .....ngày</td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                colspan="6"
                style="
                  font-size: 20px;
                  font-weight: 700;
                  text-align: center;
                  text-transform: uppercase;
                "
              >
                SIÊU ÂM THAI HÌNH THÁI THAI 18 - 22 TUẦN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="font-weight: 700">BPD</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Tiểu não</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Dạ dày</td>
              <td style="text-align: center">Bên trái</td>
            </tr>
            <tr>
              <td style="font-weight: 700">HC</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Hố sau</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Cột sống</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">AC</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">NT bên</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Bàn chân</td>
              <td style="text-align: center">&nbsp;mm</td>
            </tr>
            <tr>
              <td style="font-weight: 700">TAD</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Thùy nhộng</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Chi trên</td>
              <td style="text-align: center">3 đoạn</td>
            </tr>
            <tr>
              <td style="font-weight: 700">FL</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Hố mắt</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Bàn tay</td>
              <td style="text-align: center">Mở</td>
            </tr>
            <tr>
              <td style="font-weight: 700">HL</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">X sống mũi</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Ngón tay</td>
              <td style="text-align: center">5</td>
            </tr>
            <tr>
              <td colspan="4" style="font-weight: 700; text-align: center">
                Tần số tim thai: lần/phút
              </td>
              <td colspan="2" style="font-weight: 700; text-align: center">
                Bánh rau
              </td>
            </tr>
            <tr>
              <td style="font-weight: 700">Vị trí tim</td>
              <td style="text-align: center">trái</td>
              <td style="font-weight: 700">Gờ NTM</td>
              <td style="text-align: center">Có</td>
              <td style="font-weight: 700">Vị trí</td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">Trục tim</td>
              <td style="text-align: center">trái</td>
              <td style="font-weight: 700">VLT</td>
              <td style="text-align: center">Bình thường</td>
              <td style="font-weight: 700">Dây rốn bám</td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">Đm chủ</td>
              <td style="text-align: center">&nbsp;mm</td>
              <td style="font-weight: 700">Di động van</td>
              <td style="text-align: center">Bình thường</td>
              <td style="font-weight: 700">Nước ối</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Đm phổi</td>
              <td style="text-align: center">&nbsp;mm</td>
              <td style="font-weight: 700">Nốt Canxi</td>
              <td style="text-align: center">Không có</td>
              <td>RI ĐMTC (P):&nbsp;</td>
              <td>RI ĐMTC (T):&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">Cân nặng ước tính</td>
              <td colspan="4" style="text-align: center">.....gr</td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">H/a khác</td>
              <td colspan="4" style="text-align: center"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="font-weight: 700">KCC</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo KCC:</td>
              <td>.....tuần .....ngày</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ngày DKS</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo DKS:</td>
              <td>.....tuần .....ngày</td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                colspan="7"
                style="
                  font-size: 20px;
                  font-weight: 700;
                  text-align: center;
                  text-transform: uppercase;
                "
              >
                SIÊU ÂM DOPPLER - ĐÁNH GIÁ TĂNG TRƯỞNG CỦA THAI
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="font-weight: 700">Số lượng thai</td>
              <td colspan="4">01</td>
              <td style="font-weight: 700">Tim</td>
              <td>4 buồng</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Cử động thai</td>
              <td colspan="4">(+++)</td>
              <td style="font-weight: 700">Vách liên thất</td>
              <td>Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ngôi thai</td>
              <td colspan="4"></td>
              <td style="font-weight: 700">Nhịp tim thai</td>
              <td>140 l/p</td>
            </tr>
            <tr>
              <td style="font-weight: 700">ĐK lưỡng đỉnh (BPD)</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Phổi 2 bên</td>
              <td>Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Chu vi đầu (HC)</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Hình ảnh dạ dày</td>
              <td>(+)</td>
            </tr>
            <tr>
              <td style="font-weight: 700">ĐK ngang bụng (TAD)</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Thận 2 bên</td>
              <td>Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Chu vi bụng (AC)</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Bàng quang</td>
              <td>(+)</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Chiều dài xương đùi FL</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Cột sống</td>
              <td>Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Chiều dài x cánh tay HL</td>
              <td style="text-align: center"></td>
              <td>mm</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td style="font-weight: 700">Môi, mũi</td>
              <td>Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Cân nặng</td>
              <td style="text-align: center"></td>
              <td>gr</td>
              <td style="text-align: center"></td>
              <td>pct</td>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%">
          <tbody>
            <tr>
              <td>RI Đ/M não:&nbsp;</td>
              <td>RI Đ/M rốn:&nbsp;</td>
              <td colspan="2" style="font-weight: 700">Vị trí BR</td>
              <td colspan="2" style="text-align: center">mặt</td>
            </tr>
            <tr>
              <td style="font-weight: 700">CS não rốn</td>
              <td style="text-align: center"></td>
              <td colspan="2" style="font-weight: 700">Độ dày bánh rau</td>
              <td colspan="2" style="text-align: center">&nbsp;mm</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ống TM Arantius</td>
              <td style="text-align: center"></td>
              <td colspan="2" style="font-weight: 700">Canxi hóa bánh rau</td>
              <td colspan="2" style="text-align: center">Độ:&nbsp;</td>
            </tr>
            <tr>
              <td style="font-weight: 700">RI động mạnh TC (T)</td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">CSO</td>
              <td style="text-align: center">&nbsp;mm</td>
              <td style="font-weight: 700">CA ối</td>
              <td style="text-align: center">BT</td>
            </tr>
            <tr>
              <td style="font-weight: 700">RI động mạnh TC (P)</td>
              <td style="text-align: center"></td>
              <td style="font-weight: 700">DR Quấn cổ</td>
              <td style="text-align: center">0</td>
              <td colspan="2">2 ĐM, 1 TM</td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">H/a khác</td>
              <td colspan="4" style="text-align: center"></td>
            </tr>
            <tr>
              <td colspan="6" style="text-transform: uppercase">
                <strong>CÁC DẤU HIỆU CẦN LƯU Ý</strong>
              </td>
            </tr>
            <tr>
              <td colspan="6" style="font-style: italic">
                Cử động thai bất thường
              </td>
            </tr>
            <tr>
              <td colspan="6" style="font-style: italic">
                Đau bụng cơn tăng dần
              </td>
            </tr>
            <tr>
              <td colspan="6" style="font-style: italic">
                Ra nước hoặc ra máu
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="font-weight: 700">KCC</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo KCC:</td>
              <td>.....tuần .....ngày</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Ngày DKS</td>
              <td>...../...../ 2023</td>
              <td style="font-weight: 700">Tuổi thai theo DKS:</td>
              <td>.....tuần .....ngày</td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                colspan="6"
                style="
                  font-size: 20px;
                  font-weight: 700;
                  text-align: center;
                  text-transform: uppercase;
                "
              >
                SIÊU ÂM THAI HÌNH THÁI THAI 15 - 17 TUẦN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="font-weight: 700">BPD</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Tiểu não</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Dạ dày</td>
              <td style="text-align: center">Bên trái</td>
            </tr>
            <tr>
              <td style="font-weight: 700">HC</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Hố sau</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Cột sống</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">AC</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">NT bên</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Bàn chân</td>
              <td style="text-align: center">Không vẹo</td>
            </tr>
            <tr>
              <td style="font-weight: 700">TAD</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Hố mắt</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Bàn tay</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td style="font-weight: 700">FL</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">X sống mũi</td>
              <td style="text-align: right">&nbsp;mm</td>
              <td style="font-weight: 700">Ngón tay</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td colspan="4" style="font-weight: 700; text-align: center">
                Tần số tim thai: lần/phút
              </td>
              <td colspan="2" style="font-weight: 700; text-align: center">
                Bánh rau
              </td>
            </tr>
            <tr>
              <td style="font-weight: 700">Vị trí tim</td>
              <td style="text-align: center">trái</td>
              <td style="font-weight: 700">Gờ NTM</td>
              <td style="text-align: center">Có</td>
              <td style="font-weight: 700">Vị trí</td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td style="font-weight: 700">Trục tim</td>
              <td style="text-align: center">trái</td>
              <td style="font-weight: 700">Doppler qua van 3 lá</td>
              <td style="text-align: center">Bình thường</td>
              <td style="font-weight: 700">Dây rốn bám</td>
              <td style="text-align: center"></td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">Doppler CVA</td>
              <td colspan="2" style="text-align: center">Có sóng a</td>
              <td style="font-weight: 700">Nước ối</td>
              <td style="text-align: center">Bình thường</td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">Cân nặng ước tính</td>
              <td colspan="2" style="text-align: center"></td>
              <td>RI ĐMTC (P):&nbsp;</td>
              <td>RI ĐMTC (T):&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2" style="font-weight: 700">H/a khác</td>
              <td colspan="4" style="text-align: center"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-loading="isLoading">
      <div class="m-3 px-2">
        <div>
          <div
            class="position-relative p-1 rounded-lg bg-white text-black border-current shadow-2xl transition-all"
          >
            <div class="btn-base justify-center flex" style="min-height: 70px">
              <el-tooltip :content="$t('Quay lại')" placement="right">
                <div
                  @click="goBack"
                  role="button"
                  class="lg:absolute lg:right-4 lg:top-4 inline-block w-12 h-12 bg-white rounded-circle overflow-hidden"
                >
                  <span
                    class="flex w-12 h-12 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
                  >
                    <i class="el-icon-close m-auto text-2xl" />
                  </span>
                </div>
              </el-tooltip>
              <template
                v-if="patientVisit.status !== EXAMINATION_STATUS.CODE.CANCELED"
              >
                <button
                  v-if="
                    patientVisit.status !== EXAMINATION_STATUS.CODE.DONE &&
                    patientVisit.status !== EXAMINATION_STATUS.CODE.MISSING
                  "
                  @click="
                    updateStatusPatient(EXAMINATION_STATUS.CODE.MISSING, false)
                  "
                  class="btn-l btn-c outline-none focus:outline-none btn-drop-shadow text-white m-3 text-center"
                  :class="
                    patientVisit.status === EXAMINATION_STATUS.CODE.WAITING
                      ? 'bg-warning active:bg-yellow-800'
                      : 'active:bg-blue-900'
                  "
                >
                  <span class="fs-16 fw-500">Bỏ lượt</span>
                </button>
                <button
                  @click="
                    updateStatusPatient(EXAMINATION_STATUS.CODE.BEING, false)
                  "
                  v-if="
                    patientVisit.status === EXAMINATION_STATUS.CODE.WAITING ||
                    patientVisit.status === EXAMINATION_STATUS.CODE.MISSING ||
                    patientVisit.status ===
                      EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
                  "
                  class="btn-l btn-c outline-none focus:outline-none active:bg-blue-900 btn-drop-shadow text-white m-3"
                >
                  <span class="fs-16 fw-500 text-white">
                    {{
                      patientVisit.status ===
                      EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
                        ? $t("Bắt đầu đọc KQ")
                        : $t("Bắt đầu")
                    }}
                  </span>
                </button>
                <button
                  @click="showExtendButton = !showExtendButton"
                  v-if="
                    patientVisit.status === EXAMINATION_STATUS.CODE.BEING ||
                    patientVisit.status ===
                      EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
                  "
                  class="btn-l btn-c outline-none focus:outline-none active:bg-blue-900 btn-drop-shadow text-white m-3"
                >
                  <span class="fs-16 fw-500 text-white">Kết thúc</span>
                </button>
                <button
                  v-if="
                    isSyncPatientVisitTMatsouka &&
                    !patientVisit.indication_order.length &&
                    patientVisit.status !== EXAMINATION_STATUS.CODE.DONE &&
                    patientVisit.status !==
                      EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
                  "
                  @click="
                    updateStatusPatient(EXAMINATION_STATUS.CODE.CANCELED, false)
                  "
                  class="btn-l btn-c outline-none focus:outline-none active:bg-blue-900 btn-drop-shadow text-white m-3"
                >
                  <span class="fs-16 fw-500 text-white">Hủy khám</span>
                </button>
                <button
                  @click="aleartSendResult = true"
                  :disabled="isDisabledResult"
                  class="btn-l btn-c outline-none focus:outline-none text-white m-3"
                  :class="
                    !isDisabledResult
                      ? 'active:bg-blue-900 btn-drop-shadow'
                      : ''
                  "
                >
                  <span class="fs-16 fw-500">Gửi KQ cho BN</span>
                </button>
              </template>
            </div>
            <transition
              id="description-form"
              name="custom-classes"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              :duration="300"
            >
              <div class="btn-extend justify-center" v-if="showExtendButton">
                <div class="text-center fs-16 fw-300 justify-center">
                  Để kết thúc lượt khám, vui lòng chọn hướng xử trí tiếp theo
                </div>
                <div class="flex justify-center">
                  <button
                    @click="
                      updateStatusPatient(EXAMINATION_STATUS.CODE.DONE, false)
                    "
                    class="m-3 btn-l rounded-lg font-medium outline-none focus:outline-none btn-drop-shadow-sm border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
                  >
                    <span>Cho về</span>
                  </button>
                  <button
                    @click="handleReExamination"
                    class="m-3 btn-l rounded-lg font-medium outline-none focus:outline-none btn-drop-shadow-sm border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
                  >
                    <span>Hẹn tái khám</span>
                  </button>
                  <!-- <button
                    class="m-3 btn-l rounded-lg font-medium outline-none focus:outline-none btn-drop-shadow-sm border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
                  >
                    <span>Nhập viện</span>
                  </button>-->
                  <!-- <button
                    class="m-3 btn-l rounded-lg font-medium outline-none focus:outline-none btn-drop-shadow-sm border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
                  >
                    <span>Chuyển khoa khám</span>
                  </button>-->
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div
        class="relative row m-3"
        :class="{
          'pointer-events-none':
            patientVisit &&
            patientVisit.status === EXAMINATION_STATUS.CODE.CANCELED,
        }"
      >
        <div
          :class="
            patientVisit.method === APPOINTMENT_METHOD_STATUS.CODE.ONLINE
              ? 'col-md-8'
              : 'col-md-6'
          "
        >
          <div
            class="rounded-lg bg-white text-black border-current shadow-2xl mb-2"
          >
            <div class="p-3">
              <div
                class="flex cursor-pointer"
                @click="collapseInformation = !collapseInformation"
              >
                <p class="p-0 m-0 fs-20 fw-500">Thông tin lượt khám</p>
                <!-- status khám -->
                <div class="ml-3">
                  <div :set="(status = getApptStatus(patientVisit.status))">
                    <div :class="status.class" class="border rounded-full">
                      <div class="py-1 px-2">{{ status.text }}</div>
                    </div>
                  </div>
                </div>
                <div class="ml-auto">
                  <i
                    class="el-icon--right m-2"
                    :class="
                      collapseInformation
                        ? 'el-icon-arrow-down'
                        : 'el-icon-arrow-right'
                    "
                  ></i>
                </div>
              </div>
              <div class="mt-3" v-if="collapseInformation">
                <div class="row">
                  <p class="text-secondary col">Họ và tên:</p>
                  <p class="col">
                    {{ patientVisit.person ? patientVisit.person.name : "" }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Giới tính:</p>
                  <p class="col">
                    {{
                      patientVisit.person
                        ? getGender(patientVisit.person.gender)
                        : ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Ngày sinh:</p>
                  <p class="col">
                    {{
                      patientVisit.person &&
                      getPersonInfoString(patientVisit.person)
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Điện thoại:</p>
                  <p class="col">
                    {{ patientVisit.person ? patientVisit.person.phone : "" }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Địa chỉ:</p>
                  <p class="col">{{ getAddress(patientVisit.person) }}</p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Bảo hiểm y tế:</p>
                  <p class="col">
                    {{
                      patientVisit.person &&
                      patientVisit.person.insurance_correct_route === 1
                        ? "Đúng tuyến"
                        : patientVisit.person &&
                          patientVisit.person.insurance_correct_route === 2
                        ? "Trái tuyến"
                        : ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Người thân:</p>
                  <p class="col">
                    {{
                      patientVisit.person
                        ? patientVisit.person.emergency_contact
                        : ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">SĐT người thân:</p>
                  <p class="col">
                    {{
                      patientVisit.person
                        ? patientVisit.person.contact_phone
                        : ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Hình thức:</p>
                  <p class="col">
                    {{
                      patientVisit.method == 1
                        ? $t("schedule_page.lbl_at_workspace")
                        : $t("schedule_page.lbl_video")
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Bác sĩ:</p>
                  <p class="col">
                    {{
                      patientVisit.doctor && patientVisit.doctor.id
                        ? patientVisit.doctor.User &&
                          patientVisit.doctor.User.name
                        : patientVisit.doctor_ref_name || ""
                    }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Thời gian:</p>
                  <p class="col">
                    {{ getTime(patientVisit.start_time) }} -
                    {{ formatDateDMY(patientVisit.start_time) }}
                  </p>
                </div>
                <div class="row">
                  <p class="text-secondary col">Lý do khám:</p>
                  <p class="col">
                    {{
                      patientVisit.visit_reason ||
                      this.$t("schedule_page.lbl_dont_have")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              patientVisit.method === APPOINTMENT_METHOD_STATUS.CODE.ONLINE &&
              patientVisit.status !== EXAMINATION_STATUS.CODE.DONE &&
              patientVisit.status !== EXAMINATION_STATUS.CODE.CANCELED
            "
            class="rounded-lg bg-white text-black border-current shadow-2xl mb-2"
          >
            <div class="flex items-center p-3">
              <div class="fs-20 fw-500 ml-1">Khám trực tuyến</div>
              <div class="ml-auto flex">
                <div
                  @click="handleJoinTelemedicine"
                  class="cursor-pointer px-6 py-2 rounded-lg border border-primary bg-pri text-white flex items-center"
                >
                  <i class="el-icon-monitor text-white fs-16" />
                  <span class="ml-2 text-white font-weight-bold mb-0"
                    >Vào phòng</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <OnlineRoom
            :patientVisit="patientVisit"
            v-if="
              patientVisit.method === APPOINTMENT_METHOD_STATUS.CODE.ONLINE &&
              patientVisit.status !== EXAMINATION_STATUS.CODE.DONE &&
              patientVisit.status !== EXAMINATION_STATUS.CODE.CANCELED
            "
          />-->
          <CoverPage :person_id="patientVisit.person_id"></CoverPage>
          <UploadFile
            :patient-visit-files="patientVisit.patient_visit_file || []"
            :id="patientVisit.id"
            :status-patient-visit="patientVisit.status"
            @refresh="getPatientVisit"
          />
          <!-- <ManageDocument></ManageDocument> -->
          <ApptReceipt
            v-if="!isHiddenApprReceipt"
            :patientVisit="patientVisit"
          />
          <ReExam
            :info="detailReExam"
            :detailExam="patientVisit"
            v-if="detailReExam.id"
          />
        </div>

        <div
          class="rounded-lg text-black border-current"
          :class="
            patientVisit.method === APPOINTMENT_METHOD_STATUS.CODE.ONLINE
              ? 'col-md-4'
              : 'col-md-6'
          "
        >
          <b-overlay
            no-center
            rounded="sm"
            :show="
              patientVisit.status === EXAMINATION_STATUS.CODE.WAITING ||
              patientVisit.status === EXAMINATION_STATUS.CODE.MISSING
            "
          >
            <template #overlay>
              <div class="flex h-full">
                <div class="m-auto text-lg text-center px-3">
                  <i
                    class="el-icon-warning-outline text-4xl text-yellow-600"
                  ></i>
                  <div>
                    {{
                      $t(
                        "Bạn cần bắt đầu lượt khám để có thể thực hiện các chức năng bên dưới"
                      )
                    }}
                  </div>
                </div>
              </div>
            </template>
            <div
              class="rounded-lg p-3 hover:bg-gray-100 shadow-2xl bg-white mb-2"
            >
              <SelectDiseaseICD10
                :dataIcd="diseaseICD"
                @onChange="handleChangeDiseaseICD"
              />
            </div>

            <ConsulationTemp
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <PrescriptionTemp
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <IndicationTemp
              :name="$t('Xét nghiệm')"
              :type="INDICATION_TYPES.CODE.TEST_FORM"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <IndicationTemp
              :name="$t('Chẩn đoán hình ảnh')"
              :type="INDICATION_TYPES.CODE.ULTRASOUND"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <IndicationTemp
              :name="$t('Sinh học phân tử')"
              :type="INDICATION_TYPES.CODE.MOLECULAR_BIOLOGY"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
            <IndicationTemp
              :name="$t('Chỉ định khác')"
              :type="INDICATION_TYPES.CODE.OTHERS"
              :consultation="consultationSms"
              :patient-visit="patientVisit"
              @refresh="getPatientVisit"
            />
          </b-overlay>
        </div>
      </div>
    </div>

    <el-dialog
      :visible="aleartSendResult"
      title="Thông báo"
      width="30%"
      :before-close="handeCloseAleartSendResult"
    >
      <div v-loading="loadingSendResult">
        <span style="word-break: break-word"
          >Bạn có muốn gửi những thông tin chi tiết của lượt khám đến người
          dùng?</span
        >
        <div v-if="false" class="mt-3">
          <div v-if="patientVisit.consultation" class="ml-2">
            <span v-if="patientVisit.consultation.sign"
              >- Thông tin phiếu khám</span
            >
          </div>
          <div v-if="patientVisit.prescription.length > 0" class="ml-2">
            <span>- Thông tin đơn thuốc</span>
            <ul>
              <div v-for="item in patientVisit.prescription" :key="item.id">
                <li v-if="item.sign">{{ item.name }}</li>
              </div>
            </ul>
          </div>
          <div v-if="patientVisit.indication_order.length > 0" class="ml-2">
            <div
              v-if="
                patientVisit.indication_order.find(
                  (x) => x.cate === 1 && x.indication.find((y) => y.sign)
                )
              "
            >
              <span>- Thông tin chỉ định xét nghiệm</span>
              <ul>
                <div
                  v-for="item in patientVisit.indication_order.find(
                    (x) => x.cate === 1
                  ).indication"
                  :key="item.id"
                >
                  <li v-if="item.sign">{{ item.name }}</li>
                </div>
              </ul>
            </div>
            <div
              v-if="
                patientVisit.indication_order.find(
                  (x) => x.cate === 2 && x.indication.find((y) => y.sign)
                )
              "
            >
              <span>- Thông tin chỉ định siêu âm</span>
              <ul>
                <div
                  v-for="item in patientVisit.indication_order.find(
                    (x) => x.cate === 2
                  ).indication"
                  :key="item.id"
                >
                  <li v-if="item.sign">{{ item.name }}</li>
                </div>
              </ul>
            </div>

            <div
              v-if="
                patientVisit.indication_order.find(
                  (x) => x.cate === 3 && x.indication.find((y) => y.sign)
                )
              "
            >
              <span>- Thông tin chỉ định khác</span>
              <ul>
                <div
                  v-for="item in patientVisit.indication_order.find(
                    (x) => x.cate === 3
                  ).indication"
                  :key="item.id"
                >
                  <li v-if="item.sign">{{ item.name }}</li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex">
          <span class="dialog-footer ml-auto">
            <el-button @click="aleartSendResult = false">Thoát</el-button>
            <el-button type="primary" @click="sendResult()">Đồng ý</el-button>
          </span>
        </div>
      </div>
      <!-- {{ isSyncPatientVisitTMatsouka }} -->
    </el-dialog>

    <custom-modal
      :showModal="showRegisterAppointment"
      @hideModal="hideModal"
      :title="$t('Hẹn lịch tái khám')"
    >
      <template v-if="showRegisterAppointment">
        <appointment-form v-show="step === 'input'" @nextStep="nextStep" />
        <appointment-confirm
          v-if="step === 'confirm'"
          :data="apptDataRaw"
          @backStep="step = 'input'"
          @done="handleCreateReExamination"
          :returnRoute="returnRoute"
        />
      </template>
    </custom-modal>
  </div>
</template>
<script>
import AppointmentConfirm from '@/components/Appointment/v2/AppointmentConfirm'
import AppointmentForm from '@/components/Appointment/v2/AppointmentForm'
import CustomModal from '@/components/CustomModal/index.vue'
import ApptReceipt from '@/components/PatientVisit/ApptReceipt.vue'
import ConsulationTemp from '@/components/PatientVisit/ConsulationTemp.vue'
import ReExam from '@/components/PatientVisit/ReExam.vue'
import statusAppt from '@/constants/statusAppt'
import appUtils from '@/utils/appUtils'
import {
  APPOINTMENT_METHOD_STATUS,
  EXAMINATION_STATUS,
  INDICATION_TYPES,
  PATIENT_RECEPTION_TYPE
} from '@/utils/constants'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import ModalSelectHR from '@components/Appointment/ModalSelectHR.vue'
import IndicationTemp from '@components/PatientVisit/IndicationTemp.vue'
import OnlineRoom from '@components/PatientVisit/OnlineRoomTemp.vue'
import PrescriptionTemp from '@components/PatientVisit/PrescriptionTemp.vue'
import UploadFile from '@components/PatientVisit/UploadFilesTemp.vue'
import SelectDiseaseICD10 from '@components/SelectDiseaseICD10/index.vue'
import ModalAddConsultation from '@pages/HealthRecords/ModalAddConsultation.vue'
import PhieuKhamBenhDetail from '@pages/HealthRecords/PhieuKhamBenh/ModalAdd.vue'
import ModalViewConsultation from '@pages/HealthRecords/PhieuKhamBenh/ViewPhieuKham.vue'
import { BIcon, BOverlay } from 'bootstrap-vue'
import CoverPage from '../../../components/PatientVisit/CoverPage.vue'
// import ManageDocument from '../../../components/PatientVisit/ManageDocument.vue'

export default {
  name: 'PatientVisitDetail',
  mixins: [mixinGetPersonInfo],
  components: {
    BIcon,
    BOverlay,
    ConsulationTemp,
    ModalSelectHR,
    ModalAddConsultation,
    PhieuKhamBenhDetail,
    ModalViewConsultation,
    IndicationTemp,
    PrescriptionTemp,
    OnlineRoom,
    UploadFile,
    ApptReceipt,
    SelectDiseaseICD10,
    AppointmentForm,
    AppointmentConfirm,
    CustomModal,
    ReExam,
    CoverPage
    // ManageDocument
  },
  computed: {
    isDisabledResult () {
      if (this.patientVisit) {
        if (this.patientVisit?.consultation?.length) {
          return false
        }
        if (this.patientVisit.prescription) {
          if (this.patientVisit.prescription.find((e) => e)) {
            return false
          }
        }
        if (this.patientVisit.indication_order) {
          if (
            this.patientVisit.indication_order.find((e) =>
              e.indication.find((y) => y)
            )
          ) {
            return false
          }
        }
      }
      return true
    },
    isHiddenApprReceipt () {
      if (!this.$globalClinic?.settings) return false
      const settings = JSON.parse(this.$globalClinic?.settings)
      const isHideReceiptTMatsouka = !settings?.show_slip

      return isHideReceiptTMatsouka
    },
    isSyncPatientVisitTMatsouka () {
      if (!this.$globalClinic?.settings) return false
      const settings = JSON.parse(this.$globalClinic?.settings)
      const isSyncTMatsouka = settings?.his_connect === 'tmc'
      return isSyncTMatsouka
    }
  },
  data () {
    return {
      collapseInformation: true,
      loadingSendResult: false,
      aleartSendResult: false,
      loading: false,
      DialogVisibleStatus: false,
      consultationSms: {},
      root_type: 1,
      appointment: null,
      clinic_id: null,
      doctor_id: null,
      request_id: null,
      request: null,
      person: null,
      person_id: null,
      appt_edit: null,
      selectedHr: null,
      statusAppt,
      valueStatus: '',
      patientVisit: null,
      INDICATION_TYPES,
      showExtendButton: false,
      EXAMINATION_STATUS,
      timer: null,
      listIndicationNonResults: [],
      APPOINTMENT_METHOD_STATUS,
      isLoading: false,
      diseaseICD: {},
      showRegisterAppointment: false,
      apptDataRaw: null,
      step: 'input',
      returnRoute: {
        name: 'AppointmentDetailV2',
        query: {
          type: PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT
        }
      },
      detailReExam: {}
    }
  },
  async created () {
    const self = this

    if (!self.$route.params?.id) {
      self.$router.push({ path: '/doctor/appointment-management' })
      return
    }

    await self.getPatientVisit()
    await self.getReExamination()
    self.timer = setInterval(function () {
      self.getPatientVisit()
    }, 1000 * 30) // refetch 30s
  },
  mounted () {
    const self = this
    // this.getReExamination()
    // let user = this.$user
    self.clinic_id = this.$clinicId
  },
  destroyed () {
    this.$bus.off('change:status')
    this.cancelAutoUpdate()
  },
  watch: {
    patientVisit (data) {
      this.patientVisit = data
    }
  },
  methods: {
    handleCollapseInformation () {
      this.collapseInformation = !this.collapseInformation
    },
    handeCloseAleartSendResult () {
      this.aleartSendResult = false
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    async getPatientVisit () {
      const self = this
      self.loading = true

      try {
        const id = self.$route.params?.id
        const result = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailPatientVisit(id)

        if (result?.data) {
          self.patientVisit = result.data
          self.diseaseICD = {
            code: result.data?.icd_code || '',
            name: result.data?.icd_name || ''
          }
          self.consultationSms = {
            id: self.patientVisit?.appt?.consultation?.id,
            patient_visit_id: self.patientVisit?.id,
            health_record_id:
              self.patientVisit?.appt?.consultation?.health_record_id,
            indication_order: self.patientVisit?.indication_order
          }
          self.checkingIndicationFullResult(
            self.patientVisit?.indication_order
          )
          self.collapseInformation = self.patientVisit?.method === 1
          self.loading = false
        }
      } catch (error) {
        console.log('', error)
        self.loading = false
      } finally {
        self.loading = false
      }
    },
    checkingIndicationFullResult (orders) {
      if (!orders) return

      this.listIndicationNonResults = []

      /* eslint-disable no-unused-expressions */
      orders?.map((order) => {
        order?.indication?.map((item) => {
          if (!item?.examination_results) {
            this.listIndicationNonResults.push(item)
          }
        })
      })

      return !!this.listIndicationNonResults?.length
    },
    getApptStatus (stt) {
      switch (stt) {
        case EXAMINATION_STATUS.CODE.WAITING:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
        case EXAMINATION_STATUS.CODE.WAITING_RESULT:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Chờ kết quả'
          }
        case EXAMINATION_STATUS.CODE.BEING:
          return {
            class: 'text-green-600 border-green-600 bg-green-50',
            text: 'Đang khám'
          }
        case EXAMINATION_STATUS.CODE.DONE:
          return {
            class: 'text-blue-600 border-blue-600 bg-blue-50',
            text: 'Đã khám xong'
          }
        case EXAMINATION_STATUS.CODE.CANCELED:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Đã hủy lượt'
          }
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          return {
            class: 'text-indigo-600 border-indigo-600 bg-indigo-50',
            text: 'Chờ đọc kết quả'
          }
        case EXAMINATION_STATUS.CODE.MISSING:
          return {
            class: 'text-gray-600 border-gray-600 bg-gray-50',
            text: 'Nhỡ lượt'
          }
        default:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
      }
    },
    async getConsultationById (id) {
      try {
        const self = this
        await this.$rf
          .getRequest('DoctorRequest')
          .getConsultationByIDV2(id)
          .then((res) => {
            self.consultationSms = res.data
            console.log(self.consultationSms)
          })
      } catch (error) {
        console.log('', error)
      }
    },
    async sendResult () {
      try {
        if (!this.patientVisit.id) return
        const params = {
          patient_visit_id: this.patientVisit.id
        }
        this.loadingSendResult = true
        await this.$rf
          .getRequest('DoctorRequest')
          .sendPatientVisitResult(params)
          .then((res) => {
            console.log(res)
            this.$message({
              message: 'Gửi kết quả thành công',
              type: 'success'
            })
            this.loadingSendResult = false
            this.aleartSendResult = false
          })
      } catch (error) {
        console.log('', error)
        this.loadingSendResult = false
        this.aleartSendResult = false
      }
    },
    goBack () {
      const patientVisitRoute = JSON.parse(
        window.localStorage.getItem('patientVisitRoute')
      )
      this.$router.push({
        path: '/doctor/patient-visit',
        query: patientVisitRoute
      })
    },
    getTime (date_time) {
      return window.moment(date_time).utc().format('HH:mm:ss')
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).utc().format('DD/MM/YYYY')
    },
    getPersonInfoString (p) {
      if (!p.birthday) return ''

      return (
        this.formatDateDMY(p.birthday) +
        ' (' +
        this.getAges(p.birthday) +
        ')' +
        (p.career ? ', ' + p.career : '')
      )
    },
    getGender (gender = 1) {
      return gender === 1
        ? this.$t('schedule_page.lbl_male')
        : gender === 2
          ? this.$t('schedule_page.lbl_female')
          : gender === 3
            ? this.$t('schedule_page.lbl_other')
            : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    getStatusAppt (status) {
      if (status === undefined || status === null) return ''
      return this.statusAppt[status]
    },
    async updateStatusPatient (stt, isDone) {
      if (!isDone) {
        let _confirm = null

        if (
          stt === EXAMINATION_STATUS.CODE.MISSING &&
          (this.patientVisit.status === EXAMINATION_STATUS.CODE.BEING ||
            this.patientVisit.status ===
              EXAMINATION_STATUS.CODE.WAITING_READ_RESULT)
        ) {
          _confirm = await this.$confirm(
            this.$t('Bạn chắc chắn muốn bỏ qua lượt khám này?'),
            this.$t('Cảnh báo'),
            {
              dangerouslyUseHTMLString: true
            }
          ).catch((_) => {})

          if (!_confirm) return

          this.showExtendButton = false
        }

        if (stt === EXAMINATION_STATUS.CODE.DONE) {
          _confirm = await this.$confirm(
            this.$t('Kết thúc lượt khám cho bệnh nhân này?'),
            this.$t('Cảnh báo'),
            {
              dangerouslyUseHTMLString: true
            }
          ).catch((_) => {})

          if (!_confirm) return

          this.showExtendButton = false
        }

        if (stt === EXAMINATION_STATUS.CODE.CANCELED) {
          _confirm = await this.$confirm(
            this.$t('Bạn chắc chắn muốn hủy lượt khám cho bệnh nhân này?'),
            this.$t('Cảnh báo'),
            {
              dangerouslyUseHTMLString: true
            }
          ).catch((_) => {})

          if (!_confirm) return

          this.showExtendButton = false
        }
      }

      this.patientVisit.status = stt
      this.patientVisit.start_time = window
        .moment(this.patientVisit.start_time)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updatePatientVisitsById(this.patientVisit.id, this.patientVisit)
        .then(async (res) => {
          if (
            this.isSyncPatientVisitTMatsouka &&
            (stt === EXAMINATION_STATUS.CODE.CANCELED ||
              stt === EXAMINATION_STATUS.CODE.DONE)
          ) {
            await this.handleSyncCompletedPatientVisit({
              // MODE: 1: Huy luot kham, 2: Complete, 3: Huy XN
              mode:
                stt === EXAMINATION_STATUS.CODE.CANCELED
                  ? 1
                  : stt === EXAMINATION_STATUS.CODE.DONE
                    ? 2
                    : 0
            })
          }

          this.loading = false
          if (this.patientVisit.status === EXAMINATION_STATUS.CODE.MISSING) {
            this.$router.push({
              name: 'PatientVisitManagement',
              query: {
                tab: EXAMINATION_STATUS.NAME.MISSING.toLowerCase().replace(
                  '_',
                  '-'
                )
              }
            })
          }
          // this.getPatientVisit()
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
        })
    },
    async handleChangeDiseaseICD (data) {
      if (!this.patientVisit?.id) return

      try {
        const params = {
          icd_code: data?.code || '',
          icd_name: data?.name || ''
        }

        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .updatePatientVisitsV2(this.patientVisit?.id, params)

        if (response.status === 200) {
          this.$message({
            message: 'Lưu thành công',
            type: 'success'
          })

          await this.getPatientVisit()
        }
      } catch (error) {
        console.log(error)
        this.$message({
          message: 'Lưu thất bại',
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleSyncCompletedPatientVisit ({ mode = 0 }) {
      try {
        const params = {
          mode, // MODE: 1: Huy luot kham, 2: Complete, 3: Huy XN
          person_id: this.patientVisit?.person_id,
          patient_visit_code:
            this.patientVisit?.patient_visit_code ||
            this.patientVisit.appt?.uuid_code,
          org_id: this.$globalClinic?.org_id,
          appointment_id: this.patientVisit?.appt_id
        }
        // console.log(params)
        const response = await this.$rf
          .getRequest('TmatsuokaRequest')
          .completedPatientVisit(params)

        if (response.status === 200) {
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    handleReExamination () {
      this.showRegisterAppointment = true
      this.$router.push({
        query: {
          cr_id: this.patientVisit?.cr_id,
          person_id: this.patientVisit.person_id
        }
      })
    },
    hideModal () {
      this.showRegisterAppointment = false
      this.$router.push({ query: null })
    },
    nextStep (data) {
      if (!data) return
      this.apptDataRaw = data
      this.step = 'confirm'
    },
    async handleCreateReExamination () {
      this.showRegisterAppointment = false
      this.$router.push({ query: null })
      await this.updateStatusPatient(EXAMINATION_STATUS.CODE.DONE, true)
      this.showExtendButton = false
      this.getReExamination()
      // this.getPatientVisit()
    },
    async getReExamination () {
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getReExaminationDetail({ patient_visit_id: this.patientVisit?.id })

        if (response.status === 200) {
          this.detailReExam = response?.data?.appts
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleJoinTelemedicine () {
      const token = appUtils.getLocalToken()

      let domainOrigin = window.location?.origin
      let domain = ''

      if (domainOrigin?.includes('http://localhost')) {
        domain = 'http://localhost:8068'
      } else if (
        process.env.NODE_ENV === 'development' &&
        !domainOrigin?.includes('http://localhost')
      ) {
        domain = 'https://dev.v2.live.hodo.app/'
      } else {
        domain = 'https://v2.live.hodo.app'
      }

      const room100ms_id = this.patientVisit?.appt?.room_call?.room100ms_id
      const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${room100ms_id}&type=telemedicine&role=${'doctor'}&lang=${
        this.$lang
      }`

      console.log(routeRaw)

      // let linkElement = document.createElement('a')
      // const linkId = `meeting_${room100ms_id}`
      // linkElement.id = linkId
      // linkElement.href = routeRaw
      // window.document.body.appendChild(linkElement)

      // const linkEl = document.getElementById(linkId)
      // linkEl.setAttribute('rel', 'noopener noreferrer')
      // !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
      // linkEl.click()
      // linkEl.remove()

      window.open(
        routeRaw,
        '_blank',
        'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=1024,height=720'
      )
    }
  }
}
</script>
<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#appointment_detail_img {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.appointment-person-info .person-name {
  padding-left: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.appointment-person-info .person-avatar .avatar {
  --tblr-avatar-size: 64px;
}

.header-title {
  width: calc(100% - 32px);
}

.btn-c {
  background-color: #20419b;
  color: white;
  border: none;
}

.btn-d {
  background-color: #1a80fd;
  color: white;
  border: none;
}

.btn-drop-shadow {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
    0 2px 4px -1px rgba(0, 0, 0, 0.6);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  &:active {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.6);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  &-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    &:active {
      --tw-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
}

.hodo-table table {
  border-collapse: collapse !important;
  width: 100% !important;
  border: 1px solid #dbdde0 !important;
  margin-top: 1rem !important;
}

.hodo-table table thead th {
  text-transform: uppercase !important;
}

.hodo-table table th,
.hodo-table table td {
  padding: 8px !important;
  border: 1px solid #dbdde0 !important;
}

@media (max-width: 640px) {
  .cs-mobile-width {
    max-width: 300px;
  }
}
</style>
