<template>
  <div class="flex flex-col h-full p-4 hodo-el overflow-hidden">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="patientVisit">
        <div slot="label" class="txt-grey-900 page-title mr-4">
          {{ $t("Khám/ Tư vấn") }}
        </div>
        <PatientVisitManagement />
      </el-tab-pane>
      <el-tab-pane name="sendResult">
        <div slot="label" class="txt-grey-900 page-title mr-4">
          {{ $t("Trả kết quả") }}
        </div>
        <PatientVisitResult />
      </el-tab-pane>
      <el-tab-pane v-if="isBVHV" name="report">
        <div slot="label" class="txt-grey-900 page-title mr-4">
          {{ $t("Báo cáo đối soát") }}
        </div>
        <PatientVisitReport />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PatientVisitManagement from './PatientVisitManagementV2.vue'
import PatientVisitResult from './PatientVisitResult/index.vue'
import PatientVisitReport from './PatientVisitReport.vue'

export default {
  components: {
    PatientVisitManagement,
    PatientVisitResult,
    PatientVisitReport
  },
  name: 'PatientVisitLayout',
  data () {
    return {
      activeName: 'patientVisit'
    }
  },
  created () {
    if (this.$route.query?.type) {
      this.activeName = this.$route.query?.type
    } else {
      this.activeName = 'patientVisit'
    }
  },
  computed: {
    isBVHV () {
      return this.$globalClinic?.org_id === 4 // Bệnh viện HÙng Vương
    }
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        if (this.$route.query?.type) {
          this.activeName = this.$route.query?.type
        } else {
          this.activeName = 'patientVisit'
        }
      }
    }
  },
  methods: {
    handleClick () {
      this.$router.push({
        name: 'PatientVisitManagement',
        query: {
          ...(this.$route.query || {}),
          type: this.activeName
        }
      })
      //   this.activeName = value
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }

  @media (max-width: "1150px") {
    .hodo-el {
      .filter-item {
        width: 100% !important;

        .el-select {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
